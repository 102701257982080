<template>
	<div :style="bg">
		<Header :title="$t('fund.transfer')"></Header>
		
		<div class="column-center-content main-content">
			<div class="row-content" style="width: 100%; height: 60px; max-width: 600px;">
				<router-link to="/transferUsdt" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferUsdt')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div v-show="false" class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferUsdm" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferUsdm')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div v-show="false" class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferNasdaq" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferNasdaq')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div v-show="false" class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferHk" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferHk')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferWdt" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferWdt')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferPbt" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferPbt')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferAmbg" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferAmbg')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
			
			<div class="row-content" style="width: 100%; height: 60px; margin-top: 15px">
				<router-link to="/transferAmbc" class="row-content shadow-box-content list-box-content" style="justify-content: space-between;">
					<div class="row-content">
						<img class="transfer-img" :src="require('../../../assets/fund/exchange.png')"/>
						<label class="gray-text-title">{{$t('fund.transferAmbc')}}</label>
					</div>
					<i class="fa fa-chevron-right" aria-hidden="true" style="color: #009245;"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import Header from '../../../components/NavHeader.vue'
	
	export default {
		name: 'Transfer',
		components: {
			Header
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				}
			}
		}
	}
</script>

<style>
	.transfer-img {
		height: auto;
		width: 20px;
		margin-right: 20px;
	}
</style>
